import axios from "axios";
import {qyWechatMenuTreeData } from "@/api/clockinInterface/index";
console.log(axios)
export default {
  state: {
    menuItems: [], 
    workflowStateName: '', // 初始化workflowStateName
  },
  mutations: {
    SET_MENU_ITEMS (state, items) {
      state.menuItems = items;
    },
    SET_WORKFLOW_STATE_NAME(state, name) {
      state.workflowStateName = name;
    },
  },

  actions: {
    async fetchMenuItems ({ commit }) {
      try {
        const response = await qyWechatMenuTreeData(); // 从API获取菜单数据
        console.log(response,"response>>>>>")
        commit('SET_MENU_ITEMS', response.result);
      } catch (error) {
        console.error('Error fetching menu items:', error);
      }
    },
    updateWorkflowStateName({ commit }, name) {
      commit('SET_WORKFLOW_STATE_NAME', name);
    },
  },
}