import router from '@/router';
console.log(router, '@@!@!');
const ROUTE_PATHS = { FLOW_CHART: '/sealApprovalView' };    //盖章审请
const Name = ROUTE_PATHS.FLOW_CHART
import { Dialog, Toast } from "vant";
const USERID = JSON.parse(localStorage.getItem('userInfo'));
// export default class FormStrategy {
//   navigateToDetail(item) {
//     debugger
//     // router.push({ name:Name, params: { id: itemId.id } });
//     // router.push(`/sealApprovalView/${itemId}`);
//     router.push({
//       path:'/goOutView',
//       query: { 
//         id: item.businessCode, 
//         flowId: item.id, 
//         state: item.workFlow_State, 
//         flow: 1, 
//         node_Id: item.node_Id, 
//         node_AuditPerson: USERID.id 
//       },
//     });
//     throw new Error('You must implement the navigateToDetail method.');
//   }
// }

// import { routesMap } from '@/router/index' 

export default class FormStrategy {
  constructor(routeMap = {}, router) {
    // this.routeMap = routeMap; // 初始化路由映射表
    this.routeMap = Object.assign({}, routeMap);
    this.router = router; // 保存路由器引用
  
    // 遍历路由配置
    this.router?.options?.routes?.forEach(route => {
      // console.log(`路由路径: ${route.path}`);
      // 如果路由有名称，则打印名称
      if (route.name) {
        // console.log(`路由名称: ${route.name}`);
        this.routeMap[route.name] = route.path;
      }
      // 这里可以根据需要处理每个路由对象
    });
  }
  navigateToDetail (item,data,name) {
    debugger
    const { businessCode, id, workFlow_State, node_Id ,workFlowState,workFlow_StateName,nodeRecover,workFlow_TaskTheme} = item;
    // 构建动态路由路径
    let path = item.path;
    let modifiedPath = path.replace(/-(.)/g, (match, p1) => p1.toUpperCase());
    // const path = this.routeMap[name];
    console.log('modifiedPath>>>>',modifiedPath)
    const query = {
      id: businessCode,
      flowId: id,
      // state: workFlow_State?workFlow_State:workFlowState,
      state: (workFlow_State !== undefined && workFlow_State !== null) ? workFlow_State : (workFlowState !== undefined && workFlowState !== null ? workFlowState : ''),
      flow: 0,
      node_Id: node_Id,
      node_AuditPerson: USERID.id,
      nodeRecover:nodeRecover,
      workFlow_TaskTheme:workFlow_TaskTheme,
      workFlow_StateName:workFlow_StateName?.value
    };
    // 定义匹配的数组
    const workflowTypes = [...data];
    // 在数组中查找与 menuId 匹配的项
    const matchedItem = workflowTypes.find((type) => type.key === item.menuId);
    // 导航至目标视图
    if (matchedItem) {
      // 如果找到了匹配项，可以在这里根据 matchedItem 进行进一步操作
      console.log('匹配到的工作流类型值:', matchedItem.value);
      console.log('匹配到的工作流类型键:', matchedItem.key);
      // 根据您的业务逻辑，可能需要将这些值加入到 query 参数中
    } else {
      console.log('没有找到与 menuId 匹配的工作流类型');
      // Toast.fail('没有找到与 menuId 匹配的工作流类型');
    }
    router.push({ path, query });
  }
}