export const GlobalMixin = {
  data () {
    return {}
  },
  created () {
  },
  filters: {
    hideMiddle (val) {
      return `${val.substring(0, 3)}****${val.substring(val.length - 3)}`;
    },
  },
  methods: {
    // 截取url中的code方法            
    getUrlCode () {
      var url = location.search
      // this.winUrl = url
      var theRequest = new Object()
      if (url.indexOf("?") != -1) {
        var str = url.substr(1)
        var strs = str.split("&")
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
        }
      }
      console.log(theRequest, '企业微信Code')
      return theRequest
    },

    //判断是什么环境
    RunningPlatform () {
      let ua = navigator.userAgent.toLowerCase()
      console.log('内核', ua);
      // ios
      if (ua.indexOf('like mac os x') > -1) {
        // this.OS = 'IOS'
        console.log('内核', 'IOS');
      }
      // android
      if (ua.indexOf('android') > -1) {
        // this.OS = 'Android'
        console.log('内核', 'Android');
      }
      console.log('OS', this.OS)
    },

    detectEnvironment () {
      const userAgent = navigator.userAgent;
      // 判断是否为Node.js环境
      if (typeof process !== 'undefined' && process.versions.node) {
        alert('Node.js')
        return 'Node.js';
      }
      // 判断是否为浏览器环境
      if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        // 检测移动端浏览器
        if (/Mobi|Android|iPhone|iPad|iPod/i.test(userAgent)) {
          alert('Mobile Browser')
          return 'Mobile Browser';
        }
        // 检测桌面端浏览器
        if (/Windows|Macintosh|Linux|X11/i.test(userAgent)) {
          alert('Desktop Browser')
          return 'Desktop Browser';
        }
      }
      return 'Unknown Environment';
    },

     detectDeviceAndExecute(callback) {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
        console.log('这是苹果设备');
        callback('apple');
      } else if (ua.indexOf('android') > -1) {
        console.log('这是安卓设备');
        callback('android');
        if (ua.indexOf('mi') > -1 || ua.indexOf('xiaomi') > -1) {
          console.log('这是小米手机');
          callback('xiaomi');
        } else if (ua.indexOf('huawei') > -1 || ua.indexOf('honor') > -1) {
          console.log('这是华为或荣耀手机');
          callback('huawei');
        } else if (ua.indexOf('oppo') > -1) {
          console.log('这是OPPO手机');
          callback('oppo');
        } else if (ua.indexOf('vivo') > -1) {
          console.log('这是VIVO手机');
          callback('vivo');
        }
      } else if (/mobile/i.test(ua)) {
        console.log('这是其他类型的移动设备');
        callback('otherMobile');
      } else {
        // 非移动设备处理
        console.log('这不是移动设备');
      }
    }


  }
}