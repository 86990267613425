import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from 'vuex-persistedstate';
import menuItems from "./modules/menus.js"
Vue.use(Vuex)
export default new Vuex.Store({
	modules:{
    menuItems
	},
   plugins: [createPersistedState()], 
})
