//   career-center/operating/bidding-management/cost-accounting  标前项目成本核算详情
export default [
  {
    path: '/career-center/operating/bidding-management/cost-accounting',
    name: 'costAccounting',
    meta: { title: "标前项目成本核算" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/costAccounting.vue'),
  },
  {
    path: '/career-center/operating/operating-operating-ledger/project-disclosure',
    name: 'projectDisclosure',
    meta: { title: "项目交底详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/projectDisclosure.vue'),
  },
  {
    path: '/career-center/operating/contract-management/contract-disclosure',
    name: 'contractDisclosure',
    meta: { title: "合同交底详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/contractDisclosure.vue'),
  },
  {
    path: '/career-center/operating/contract-management/supplementary-agreement-review',
    name: 'supplementaryAgreement',
    meta: { title: "补充协议评审详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/supplementaryAgreement.vue'),
  },
  {
    path: '/career-center/operating/contract-management/contract-review',
    name: 'contractReview',
    meta: { title: "合同视图" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/contractReview.vue'),
  },
  {
    path: '/career-center/operating/bidding-management/tender-review',
    name: 'bidReview',
    meta: { title: "投标信息详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/bidReview.vue'),
  },
  {
    path: '/career-center/operating/bidding-management/tender-bond-payment',
    name: 'depositPayment',
    meta: { title: "投标保证金支付详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/depositPayment.vue'),
  },
  {
    path: '/career-center/operating/bidding-management/evaluation-of-bids',
    name: 'evaluationOfBids',
    meta: { title: "投标信息" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/evaluationOfBids.vue'),
  },
  {
    path: '/consulting-management-center/cost-department/Issue-a-report',
    name: 'lssueReport',
    meta: { title: "出具报告详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/lssueReport.vue'),
  },
  {
    path: '/consulting-management-center/cost-department/quality-control/consulting-report',
    name: 'consultingReportDraft',
    meta: { title: "咨询报告初稿详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/consultingReportDraft.vue'),
  },
  {
    path: '/consulting-management-center/cost-department/quality-control/audit-record-form',
    name: 'auditRecords',
    meta: { title: "审核记录表详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/auditRecords.vue'),
  },
  {
    path: '/consulting-management-center/cost-department/quality-control/third-level-review',
    name: 'thirdLevelReview',
    meta: { title: "三级审核详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/thirdLevelReview.vue'),
  },
  {
    path: "/consulting-management-center/cost-department/quality-control/meeting-minutes",
    name: 'coordinationMeeting',
    meta: { title: "协调会议纪要详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/coordinationMeeting.vue'),
  },
  {
    path: "/consulting-management-center/cost-department/quality-control/on-site-survey-records",
    name: 'surveyRecords',
    meta: { title: "现场勘察记录详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/surveyRecords.vue'),
  },
  {
    path: "/consulting-management-center/cost-department/quality-control/project-Implementation-plan",
    name: 'projectImplementation',
    meta: { title: "项目实施方案详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/projectImplementation.vue'),
  },
  //勘察设计部
  {
    path: "/consulting-management-center/surveyDesignDepartment/designChangeManagement",
    name: 'designChangeManagement',
    meta: { title: "设计变更管理详情" },
    queryTransform: item => ({
      id: item.id, 
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/designChangeManagement.vue'),
  },
  {
    path: "/consulting-management-center/surveyDesignDepartment/drawingReviewAndOptimization",
    name: 'designChanges',
    meta: { title: "图纸审核及优化详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/designChanges.vue'),
  },
  //招投标部
  {
    path: "/consulting-management-center/bidding-Department/prequalificationReviewMaterials",
    name: 'prequalificationReviewMaterials',
    meta: { title: "资格预审评审资料详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/preQualification.vue'),
  },
  {
    path: "/consulting-management-center/bidding-Department/noticeOfPreQualification",
    name: 'noticeOfPreQualification',
    meta: { title: "资格预审合格通知书详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/noticeOfPreQualification.vue'),
  },
  {
    path: "/consulting-management-center/bidding-Department/abnormalSituationLedger",
    name: 'abnormalSituationLedger',
    meta: { title: "资格预审异常详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/abnormalSituationLedger.vue'),
  },
]