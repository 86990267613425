// 全资-监理部
export default [
  {
    path: '/engineering-management/daily-management/application-report-processing',
    name: 'applicationReportProcessing',
    meta: { title: "专项报告申请单" },
    queryTransform: item => ({
      id: item.id,
    }),
    //申请报告处理流程
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/applicationReportProcessing.vue'),
  },
  {
    path: '/engineering-management/daily-management/company-inspection-notice',
    name: 'companyInspectionNotice',
    meta: { title: "公司巡查通知单" },
    queryTransform: item => ({
      id: item.id,
    }),
    //公司巡查通知单流程
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/companyInspectionNotice.vue'),
  },
  {
    path: '/engineering-management/daily-management/display-stand',
    name: 'displayStand',
    meta: { title: "公示台" },
    queryTransform: item => ({
      id: item.id,
    }),
    //公示台
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/displayStand.vue'),
  },
  {
    path: '/engineering-management/daily-management/seal-application',
    name: 'sealApplication',
    meta: { title: "刻章申请" },
    queryTransform: item => ({
      id: item.id,
    }),
    //刻章申请
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/sealApplication.vue'),
  },
  {
    path: '/engineering-management/daily-management/settlement-contract',
    name: 'settlementContract',
    meta: { title: "合同结算" },
    queryTransform: item => ({
      id: item.id,
    }),
    //合同结算
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/settlementContract.vue'),
  },
  {
    path: '/engineering-management/project-management/supervision-project-notification',
    name: 'supervisionProjectNotification',
    meta: { title: "监理项目通知" },
    queryTransform: item => ({
      id: item.id,
    }),
    //监理项目通知
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/supervisionProjectNotification.vue'),
  },
  {
    path: '/engineering-management/project-management/monthly-cost',
    name: 'monthlyCost',
    meta: { title: "月初成本分析" },
    queryTransform: item => ({
      id: item.id,
    }),
    //监理项目通知
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/monthlyCost.vue'),
  },
  {
    path: '/engineering-management/project-department-management/construction',
    name: 'construction',
    meta: { title: "项目部组建" },
    queryTransform: item => ({
      id: item.id,
    }),
    //项目部组建
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/construction.vue'),
  },
  {
    path: '/engineering-management/project-department-management/revocation',
    name: 'revocation',
    meta: { title: "项目部撤销" },
    queryTransform: item => ({
      id: item.id,
    }),
    //项目部组建
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/revocation.vue'),
  },
  {
    path: '/jltcompliancestandard',
    name: 'jltcompliancestandard',
    meta: { title: "项目信息化达标验收" },
    queryTransform: item => ({
      id: item.id,
    }),
    //项目部组建
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/jltcompliancestandard.vue'),
  },
  {
    path: '/consulting-management-center/bidding-Department/reviewBiddingDocuments',
    name: 'reviewBiddingDocuments',
    meta: { title: "招标文件审核" },
    queryTransform: item => ({
      id: item.id,
    }),
    //全资-招投标部-文件及公告管理-招标文件审核
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/reviewBiddingDocuments.vue'),
  },
  {
    path: '/consulting-management-center/bidding-Department/clarifyQ&ADocuments',
    name: 'clarifyQ&ADocuments',
    meta: { title: "澄清答疑文件" },
    queryTransform: item => ({
      id: item.id,
    }),
    //全资-招投标部-文件及公告管理-澄清答疑文件
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/clarifyQ&ADocuments.vue'),
  },
  {
    path: '/consulting-management-center/bidding-Department/announcementBiddingPrices',
    name: 'announcementBiddingPrices',
    meta: { title: "招标价公布" },
    queryTransform: item => ({
      id: item.id,
    }),
    //全资-招投标部-文件及公告管理-招标价公布
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/announcementBiddingPrices.vue'),
  },
]