export default [
  {
    path: "/partyMember-ActivityPlan",
    name: "partyMemberActivityPlan",
    meta: { title: "党员活动计划详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/partyMemberActivityPlan.vue"
      ),
  },
  {
    path: "/plan-check/personal-plan/annual-plan",
    name: "planCheckPersonalAnnualPlan",
    meta: { title: "个人年度计划详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/planCheckPersonalAnnualPlan.vue"
      ),
  },
  {
    path: "/plan-check/personal-plan/monthly-plan",
    name: "planCheckPersonalMonthlyPlan",
    meta: { title: "个人月度计划编制" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/planCheckPersonalMonthlyPlan.vue"
      ),
  },
  {
    path: "/plan-check/personal-plan/weekly-plan",
    name: "planCheckPersonalWeeklyPlan",
    meta: { title: "个人周计划编制" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/planCheckPersonalWeeklyPlan.vue"
      ),
  },
  {
    path: "/plan-check/personal-plan/daily-work-log",
    name: "planCheckPersonalDailyWorkLog",
    meta: { title: "个人每日工作日志详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/planCheckPersonalDailyWorkLog.vue"
      ),
  },
  {
    path: "/plan-check/department-plan/annual-plan",
    name: "planCheckDepartmentPlanAnnualPlan",
    meta: { title: "部门年度计划详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/planCheckDepartmentPlanAnnualPlan.vue"
      ),
  },
  {
    path: "/plan-check/department-plan/monthly-plan",
    name: "planCheckDepartmentPlanMonthlyPlan",
    meta: { title: "部门月度计划详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/planCheckDepartmentPlanMonthlyPlan.vue"
      ),
  },
  {
    path: "/plan-check/department-plan/weekly-plan",
    name: "planCheckDepartmentPlanWeeklyPlan",
    meta: { title: "部门周计划详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/planCheckDepartmentPlanWeeklyPlan.vue"
      ),
  },
  {
    path: "/plan-check/department-plan/daily-work-log",
    name: "planCheckDepartmentPlanDailyWorkLog",
    meta: { title: "部门每日工作日志详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/planCheckDepartmentPlanDailyWorkLog.vue"
      ),
  },
  {
    path: "/InvestmentConsulting",
    name: "InvestmentConsulting",
    meta: { title: "前期部-咨询-投资咨询详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/InvestmentConsulting.vue"
      ),
  },
  {
    path: "/LandConsulting",
    name: "LandConsulting",
    meta: { title: "前期部-咨询-土地咨询详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/LandConsulting.vue"
      ),
  },
  {
    path: "/PreliminaryConsultation",
    name: "PreliminaryConsultation",
    meta: { title: "前期部-咨询-前期咨询详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/LandConsulting.vue"
      ),
  },
  {
    path: "/ProjectApproval",
    name: "ProjectApproval",
    meta: { title: "前期部-报批报建-立项详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/ProjectApproval.vue"
      ),
  },
  {
    path: "/Ground",
    name: "Ground",
    meta: { title: "前期部-报批报建-土地详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/Ground.vue"
      ),
  },
  {
    path: "/GetAlongWith",
    name: "GetAlongWith",
    meta: { title: "前期部-报批报建-规划详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/GetAlongWith.vue"
      ),
  },

  {
    path: "/ConstructionManagement",
    name: "ConstructionManagement",
    meta: { title: "前期部-报批报建-建管详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/ConstructionManagement.vue"
      ),
  },
  {
    path: "/HouseProperty",
    name: "HouseProperty",
    meta: { title: "前期部-报批报建-房产详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/HouseProperty.vue"
      ),
  },
  {
    path: "/AcceptanceChecktt",
    name: "AcceptanceChecktt",
    meta: { title: "前期部-报批报建-报批报建验收详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/AcceptanceChecktt.vue"
      ),
  },
  {
    path: "/ownership",
    name: "ownership",
    meta: { title: "前期部-报批报建-权属详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/ownership.vue"
      ),
  },

  {
    path: "/consulting-management-center/bidding-Department/contractReview",
    name: "biddingDepartmentContractReview",
    meta: { title: "招投标部-招标项目管理-招标合同审核" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/biddingDepartmentContractReview.vue"
      ),
  },

  {
    path: "/consulting-management-center/bidding-Department/prequalificationDocuments",
    name: "prequalificationDocuments",
    meta: { title: "招投标资格预审文件详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/prequalificationDocuments.vue"
      ),
  },

  {
    path: "/consulting-management-center/bidding-Department/clarificationofPrequalification",
    name: "clarificationofPrequalification",
    meta: { title: "资格预审澄清详情" },
    queryTransform: (item) => ({
      id: item.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "moduleA" */ "@/views/detailedProcess/clarificationofPrequalification.vue"
      ),
  },
];
