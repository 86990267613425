
import moduleARoutes from '@/modules/router'; // 引入模块人事,行政的路由
import causeRouter from '@/modules/router/causeRouter';   // 引入事业发展的路由
import engineeringRouter from '@/modules/router/engineeringRouter'; //引入监理部模块
import ttRouter from "@/modules/router/ttRouter"; // 引入党群的路由
import financialManagementCenter from '@/modules/router/financialManagementCenter'; // 财务管理中心 && 全过程咨询-合同管理
import fullConsultationsSurveyDesign from "@/modules/router/fullConsultationsSurveyDesign"; // 全过程咨询-勘察设计部


// 合并两个路由模块
const allRoutes = [...moduleARoutes, ...ttRouter, ...engineeringRouter, ...financialManagementCenter, ...causeRouter, ...fullConsultationsSurveyDesign];
export default allRoutes;
