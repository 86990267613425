// 财务管理中心
export default [
    {
        path: '/money-center/billing-collection/contract-billing',
        name: 'contractInvoicingApprovalDetails',
        meta: { title: "合同开票审批详情" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/contractInvoicingApprovalDetails.vue'),
    },
    // {
    //     path: '/career-center/operating/bidding-management/cost-accounting',
    //     name: 'projectCostAccountingReview',
    //     meta: { title: "项目成本核算审核详情" },
    //     queryTransform: item => ({
    //     id: item.id,
    //     }),
    //     component: () => import('@/views/detailedProcess/projectCostAccountingReview.vue'),
    // },
    {
        path: '/career-center/operating/bidding-management/bidding-information',
        name: 'bidInformationReview',
        meta: { title: "投标信息审核详情" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/bidInformationReview.vue'),
    },
    {
        path: '/money-center/capital-management/tender-bond',
        name: 'recoveryOfBidSecurityDeposit',
        meta: { title: "投标保证金回收" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/recoveryOfBidSecurityDeposit.vue'),
    },
    {
        path: '/money-center/capital-management/expense-claim',
        name: 'expenseReimbursementApplication',
        meta: { title: "费用报销申请" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/expenseReimbursementApplication.vue'),
    },
    {
        path: '/consulting-management-center/project-management/contract-managements',
        name: 'contractManagementApplication',
        meta: { title: "合同管理申请" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/contractManagementApplication.vue'),
    },

    
];