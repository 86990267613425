// 全过程咨询-勘探设计部

export default [
    {
        path: "/consulting-management-center/surveyDesignDepartment/surveyTaskBook",
        name: 'surveyTaskBook',
        meta: { title: "勘察任务书" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/surveyTaskBook.vue'),
    },
    {
        path: "/consulting-management-center/surveyDesignDepartment/feasibilityStudySurvey",
        name: 'feasibilityStudySurvey',
        meta: { title: "可行性研究勘察" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/feasibilityStudySurvey.vue'),
    },
    {
        path: "/consulting-management-center/surveyDesignDepartment/preliminarySurvey",
        name: 'preliminarySurvey',
        meta: { title: "初步勘察" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/preliminarySurvey.vue'),
    },
    {
        path: "/consulting-management-center/surveyDesignDepartment/surveyPlanReview",
        name: 'surveyPlanReview',
        meta: { title: "方案评审" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/surveyPlanReview.vue'),
    },
    {
        path: "/consulting-management-center/surveyDesignDepartment/detailedSurvey",
        name: 'detailedSurvey',
        meta: { title: "详细勘察" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/detailedSurvey.vue'),
    },
    {
        path: "/consulting-management-center/surveyDesignDepartment/constructionSurvey",
        name: 'constructionSurvey',
        meta: { title: "施工勘察" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/constructionSurvey.vue'),
    },
    {
        path: "/consulting-management-center/surveyDesignDepartment/surveyPlanNodes",
        name: 'surveyPlanNodes',
        meta: { title: "勘察计划节点" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/surveyPlanNodes.vue'),
    },
    {
        path: "/consulting-management-center/surveyDesignDepartment/designSpecification",
        name: 'designSpecification',
        meta: { title: "设计任务书" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/designSpecification.vue'),
    },
    {
        path: "/consulting-management-center/surveyDesignDepartment/Planning-SchemeApproval",
        name: 'planningSchemeApproval',
        meta: { title: "规划、方案审批" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/planningSchemeApproval.vue'),
    },
    {
        path: "/consulting-management-center/surveyDesignDepartment/designProposal",
        name: 'designProposal',
        meta: { title: "设计计划书(节点)" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/designProposal.vue'),
    },
    {
        path: "/consulting-management-center/surveyDesignDepartment/designKickoffMeeting",
        name: 'designKickoffMeeting',
        meta: { title: "设计启动会" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/designKickoffMeeting.vue'),
    },
    {
        path: "/consulting-management-center/surveyDesignDepartment/designFundingConditions",
        name: 'designFundingConditions',
        meta: { title: "设计提资条件" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/designFundingConditions.vue'),
    },
    {
        path: "/consulting-management-center/surveyDesignDepartment/PlanReviewFile",
        name: 'internalReviewComments',
        meta: { title: "方案评审详情" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/internalReviewComments.vue'),
    },
    {
        path: "/consulting-management-center/surveyDesignDepartment/Opinion",
        name: 'expertReviewOpinions',
        meta: { title: "专家评审意见" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/expertReviewOpinions.vue'),
    },
    {
        path: "/consulting-management-center/bidding-Department/bidOpeningLedger",
        name: 'bidOpeningLedger',
        meta: { title: "开标异常情况" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/bidOpeningLedger.vue'),
    },
    {
        path: "/consulting-management-center/bidding-Department/bidEvaluationLedger",
        name: 'bidEvaluationLedger',
        meta: { title: "异常情况台账详情" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/bidEvaluationLedger.vue'),
    },
    {
        path: "/consulting-management-center/bidding-Department/theLetterAcceptance",
        name: 'theLetterAcceptance',
        meta: { title: "定标中标通知书" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/theLetterAcceptance.vue'),
    },
    {
        path: "/consulting-management-center/bidding-Department/billManagement",
        name: 'billManagement',
        meta: { title: "票据管理详情" },
        queryTransform: item => ({
        id: item.id,
        }),
        component: () => import('@/views/detailedProcess/billManagement.vue'),
    },
]