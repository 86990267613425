<template>
  <div id="app" style="padding-bottom: 10%;">
    <div class="content-main">
      <!-- <router-view></router-view> -->
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
  </div>
</template>
<script src="//res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script src="https://wwcdn.weixin.qq.com/node/wework/wwopen/js/wwLogin-1.2.5.js"></script>
<script src="https://open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js"></script>
<script>
import axios from "axios";
import wx from "weixin-js-sdk";
import {oauthUrl,getAccesToken,sysLoginInface,getWeChatInfo} from "@/api/wcLogin";
import { GlobalMixin } from "@/mixins/globalMixin";
import { Dialog, Toast } from "vant";
import { mapActions } from "vuex";
const isWechat = () => {
  return (
    String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) ===
    "micromessenger"
  );
};
export default {
  name: "App",
  mixins: [GlobalMixin],
  data() {
    return {
      access_token: "",
      code: "",
      agentid: "1000036",
      expires_in: "", //有效期（秒）
      userId: localStorage.setItem("userid", ""),
    };
  },

  created() {
    this.os(); //区分设备
    this.getElement(); //适配屏幕
    this.LoginAsync();
    this.resetSetItem("isStaticTabBar", 1);
  },
  mounted() {},
  methods: {
    // ...mapActions(["fetchMenuItems"]),
    async LoginAsync() {
      let wxuserId = localStorage.getItem("user_id");
      console.log(wxuserId, "\\\\\\\\\\\\\\\\>>>>>>>>>>>>>>>>>>>");
      try {
        // await this.getCode()
        await this.getToken();
        if (wxuserId == "" || wxuserId == null) {
          //   setTimeout(() => {
          //   this.wxLogin();
          // }, 1000);
          await new Promise((resolve) => setTimeout(resolve, 1500));
          this.wxLogin();
        }
        setTimeout(() => {
          this.wxLoginPc();
        }, 500);
      } catch (error) {
        console.error(error);
      }
    },

    //获取企业微信的登录access_token
    async getToken() {
      setTimeout(async () => {
        let that = this;
        const response = await getAccesToken();
        console.log(response, "获取企业微信的登录access_token");
        that.access_token = response.result.access_token;
        window.localStorage.setItem(
          "access_token",
          response.result.access_token
        );
      }, 1000);
    },

    //企业微信处理授权登录
    async wxLogin() {
      try {
        let token = localStorage.getItem("access_token");
        const params = {
          code: this.getUrlCode().code,
          type: "信息化测试",
          accessToken: token,
        };
        const res = await oauthUrl(params).catch((error) => {
          throw error; // 如果需要在catch块外部继续处理这个错误，可以重新抛出
        });

        if (res.code === 200) {
          console.log(res.code, "企业微信处理授权登录>>>>>>>>>");
          this.userId = res.result.userId;
          window.localStorage.setItem("user_id", res.result.userId);
          console.log(this.userId, "测试userId");
          console.log(res, "企业微信处理授权登录");
        } else {
          Dialog.alert({
            message: "授权失败，请重新授权",
          }).then((res) => {
            // on close
          });
        }
      } catch (error) {
        // 处理所有与wxLogin相关联的错误
        Dialog.alert({
          message: "授权过程中出现错误，请稍后重试",
        }).then(() => {
          // on close
        });
      }
    },

    //企业微信处理授权登录Pc的登录
    async wxLoginPc() {
      let data = {
      weChatUserId: localStorage.getItem("user_id"),
      //  weChatUserId: "ZhangYueBin", // 路卿
      };
      try {
        const res = await sysLoginInface(data);
        if (res.code == 200) {
          console.log(res.code, "企业微信处理授权登录Pc的登录++++++");
          window.localStorage.setItem("setToken", res.result.accessToken);
          // this.fetchMenuItems(); // 页面加载时获取菜单数据
          this.getWxInfo();
        }
      } catch (error) {
        Dialog.alert({
          message: error,
        });
      }
    },

    //根据企业微信id获取详细信息
    async getWxInfo() {
     let weChatUserId = localStorage.getItem("user_id");
    // let weChatUserId = 'ZhangYueBin'; // 路卿
      try {
        let res = await getWeChatInfo({ weChatUserId: weChatUserId });
        if (res.code == 200) {
          let userInfo = JSON.stringify(res.result.sysUserInfo);
          localStorage.setItem("userInfo", userInfo);
          console.log(res.result.sysUserInfo, "");
        }
      } catch (error) {
        Dialog.alert({
          message: error,
        });
      }
    },
    // async  getWxInfo(maxRetries = 2) {  //实现接口异常则自动刷新并重新请求
    //     let weChatUserId = localStorage.getItem("user_id"); // 获取实际的用户ID
    //     // let weChatUserId = "0be2f4a9-4681-4f48-ad4e-af62b66487f3"; //邓抄海
    //     let retryCount = 0;
    //     while (retryCount < maxRetries) {
    //       try {
    //         let res = await getWeChatInfo({ weChatUserId: weChatUserId });
    //         if (res.code === 200) {
    //           let userInfo = JSON.stringify(res.result.sysUserInfo);
    //           localStorage.setItem("userInfo", userInfo);
    //           console.log(res.result.sysUserInfo, "");
    //           return; // 请求成功，退出循环
    //         } else {
    //           console.error(`请求失败，状态码：${res.code}`);
    //         }
    //       } catch (error) {
    //         Dialog.alert({
    //           message: error
    //           })
    //       }
    //       retryCount++;
    //       if (retryCount < maxRetries) {
    //         // 延迟一段时间后再重试
    //         await new Promise(resolve => setTimeout(resolve, 4000 * retryCount));
    //         console.log(`尝试 ${retryCount + 1} / ${maxRetries}`);
    //       } else {
    //         Dialog.alert({
    //           message: `请求失败，尝试了 ${maxRetries} 次`,
    //         });
    //       }
    //     }
    //  },

    // 设备区分 （安卓、火狐、平板、PC）
    os() {
      var ua = navigator.userAgent,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isTablet =
          /(?:iPad|PlayBook)/.test(ua) ||
          (isAndroid && !/(?:Mobile)/.test(ua)) ||
          (isFireFox && /(?:Tablet)/.test(ua)),
        isPC = !/Android|webOS|iPhone|iPod|BlackBerry/i.test(ua);
      return {
        isTablet: isTablet,
      };
    },
    getElement() {
      var docEl = document.documentElement,
        resizeEvt =
          "orientationchange" in window ? "orientationchange" : "resize",
        recalc = function () {
          var clientWidth = docEl.clientWidth;
          if (!clientWidth) return;
          /*设置一个屏幕最大宽度临界值，当大于这个宽度时，字体也不放大了*/
          if (clientWidth >= 1280) {
            docEl.style.fontSize = "85px";
          } else {
            /*750是设计稿的宽度，100是基数为了方便换算，也可以为20，或者浏览器默认值16(px)*/
            docEl.style.fontSize = 100 * (clientWidth / 750) + "px";
          }
        };
      if (!window.addEventListener) return;
      window.addEventListener(resizeEvt, recalc, false);
      window.addEventListener("DOMContentLoaded", recalc, false);
    },
  },
};
</script>

<style scoped>
@media screen and (min-device-width: 600px) {
  html {
    font-size: 64px !important;
    background-color: black;
  }

  body {
    width: 100vw;
    max-width: 550px;
    height: 100vh;
    max-height: 100vh;
    margin: 0 auto !important;
  }   
}
/* @media screen and (max-device-width: 600px) {
            .bottomBox {
                width: 100%;
            }
        } */

  
</style>
