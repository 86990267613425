import request from '@/utils/request'



//企业微信处理授权登录回调
export function oauthUrl(query) {
  return request({
    url: 'sysLogin/enterpriseWeChatHandleCallback',
    method: 'get',
    params: query
  })
}

//根据企业微信id获取详细信息
export function getWeChatInfo(query) {
  return request({
    url: 'sysLogin/loginUserInfoByWeChatUserId',
    method: 'get',
    params: query
  })
}

//企业微信静默登录
export function sysLoginInface(data) {
  return request({
    url: '/sysLogin/weChatLogin',
    method: 'post',
    headers:{
      'Referrer-Policy': 'https://wx.gdtianheng.com/api/',
      'Access-Control-Allow-0rigin':'*'
     },
    data
  })
}



//企业微信获取jsdk参数
export function jsSdkParameter(query) {
  // let url='http://wx.gdtianheng.com'
  // var url = window.location.href 
  // let url=encodeURIComponent('http://wx.gdtianheng.com')
  return request({
    url: `/sysWeChat/jsSdkParameter`,
    method: 'get',
    params: query
  })
}


export function getAccesToken(params) {
  return request({
    url: 'sysLogin/accessToken/信息化测试',
    method: 'get',
    params: params
  })
}

export function oauthUser(query) {
  return request({
    url: '/wecom/oauthUser',
    method: 'get',
    params: query
  })
}


export function queryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}



//企业微信查询部门树
export function treeData(query) {
  return request({
    // url: '/sysOrg/treeData',
    url: '/sysOrg/TreeDataNew',
    method: 'get',
    params: query
  })
}
