import axios from "axios";
import { Toast } from "vant";
const baseUrl = process.env.VUE_APP_BASE_URL || "";
const apiPath = process.env.VUE_APP_BASE_API || ""; // 本地代理才能使用到的
console.log(process.env)
const service = axios.create({
  // baseURL: baseUrl + apiPath, // api的base_url
  baseURL: "/api",
  //  baseURL: "https://wx.gdtianheng.com/api/",
  // baseURL: "http://192.168.1.100/api/",
  // baseURL:process.env.NODE_ENV === 'development' ? '/api' : window.webConfig.webApiBaseUrl,
  timeout: 60 * 10000, // request timeout 60s
  withCredentials: false,

});

// request interceptor
service.interceptors.request.use((config) => {
  console.log('Request Config:', config.params);
  let token = localStorage.getItem("setToken");
  let accessToken = localStorage.getItem("setAccessToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (accessToken) {
    config.headers["Access-Token"] = accessToken;
  }
  config.headers["Content-Type"] = "application/json";
  if (config.header && config.headers["Content-Type"]) {
    config.headers["Content-Type"] = config.header["Content-Type"];
  }
  return config;
},
  (error) => {
    Promise.reject(error);
  });

// service.interceptors.response.use((response) => {
//     const res = response.data || {};
//     if (res.code === 200 || res.code === 0) {
//       return res;
//       debugger
//     } else {
//       const errorMessage = `${res.message || '未知错误'} `;
//       throw new Error(errorMessage);
//     }
//   },
//   (error) => {
//     if (error.response) {
//       const errorMessage = error.response.data.message || '服务器错误';
//       console.error(errorMessage);
//     } else if (error.request) {
//       console.error('请求未发送成功，可能是网络问题');
//     } else {
//       console.error('请求创建失败', error.message);
//     }
//     return Promise.reject(error);
//   }
// );

service.interceptors.response.use(
  (response) => {
    const res = response.data || {};
    if (res.code === 200 || res.code === 0) {
      return res;
    } else {
      const errorMessage = `${res.message || '未知错误'} `;
      class CustomError extends Error {  // 自定义错误类型,可以处理复杂的业务逻辑
        constructor(message) {
          super(message);
          this.name = 'CustomError';
        }
      }
      // throw new CustomError(errorMessage);
      throw errorMessage
    }
  },
  (error) => {
    if (error.response) {
      // 根据HTTP状态码显示不同的错误消息
      switch (error.response.status) {
        case 400:
          this.$message.error('客户端错误，请检查您的输入');
          break;
        case 401:
          this.$message.error('未授权，请登录');
          break;
        case 403:
          this.$message.error('禁止访问');
          break;
        case 404:
          this.$message.error('找不到资源');
          break;
        case 500:
          this.$message.error('服务器内部错误');
          break;
        default:
          this.$message.error('服务器错误');
          break;
      }
      console.error(error.response.data.message || '服务器错误');
    } else if (error.request) {
      console.error('请求未发送成功，可能是网络问题');
    } else {
      console.error('请求创建失败', error.message);
    }
    return Promise.reject(error);
  }
);

export default service;
