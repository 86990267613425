<template>
  <van-row class="themBox" type="flex" justify="space-between">
    <van-col span="4">
      <div class="TaskTheme">主题</div>
    </van-col>
    <van-col span="20" style="text-align: left; font-size: 15px;">
      {{ themeText }}
    </van-col>
  </van-row>
</template>

<script>
export default {
  name: 'TaskTheme',
  props: {
    themeText: {
      type: String,
      required: true
    },
    StateName: {
     type: String,
     required: true
    }
  }
}
</script>

<style scoped>
.themBox {
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  line-height: 40px;
  margin: 0 20px;
  padding-bottom: 10px;
  height:40px;
  display:flex;
}
.TaskTheme {
  background: #1989fa;
  border-radius: 100%;
  width:40px;
  color: #fff;
}
</style>